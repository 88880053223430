import { useQueryClient } from '@tanstack/react-query';
import {
	fetchSettingsQuery,
	saveSettingsQuery,
	usePoMutation,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { PoIdentityType, useIdentity } from '@gov-nx/auth/common';
import { LocalizationLanguage } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { useUserStore } from '@gov-nx/store/portal-obcana';

export const useUserLanguageLoader = () => {
	const key = 'LANG';
	const queryKey = ['user-settings-app-portal-obcana', key];
	const poIdentityContext = useIdentity() as Nullable<PoIdentityType>;
	const { setUserSettingLanguage } = useUserStore();
	const queryClient = useQueryClient();

	usePoQuery({
		queryKey,
		enabled: poIdentityContext?.isAuthenticated,
		queryFn: async (): Promise<LocalizationLanguage> => {
			const data = await fetchSettingsQuery(key);
			if (!data.hodnota) {
				return LocalizationLanguage.Czech;
			}

			const transformed =
				data.hodnota === 'en'
					? LocalizationLanguage.English
					: LocalizationLanguage.Czech;

			setUserSettingLanguage(transformed);

			return transformed;
		},
	});

	const saveUserSettingMutation = usePoMutation({
		mutationFn: (language: LocalizationLanguage) =>
			saveSettingsQuery({ klic: key, hodnota: language }),
		onSuccess: (_, variables) => {
			queryClient.setQueryData(queryKey, variables);
		},
	});

	return {
		updateLanguage: saveUserSettingMutation.mutate,
	};
};
