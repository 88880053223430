import { Optional } from '@gov-nx/core/types';

export const delay = (time: number): Promise<void> => {
	return new Promise((resolve) => {
		setTimeout(resolve, time);
	});
};

export const throttle = (fn: (value: string) => void, delay: number) => {
	let timer: ReturnType<typeof setTimeout> = 0 as unknown as ReturnType<
		typeof setTimeout
	>;
	return (value: Optional<string>) => {
		if (!value) return;
		clearTimeout(timer);
		timer = setTimeout(() => fn(value), delay);
	};
};

export function debounce(callback: () => void, wait: number) {
	let timerId: ReturnType<typeof setTimeout> = 0 as unknown as ReturnType<
		typeof setTimeout
	>;
	return () => {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			callback();
		}, wait);
	};
}
