import {
	GovDropdown,
	GovFormControl,
	GovIcon,
	GovTag,
} from '@gov-design-system-ce/react';
import { useState } from 'react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	dataBoxLabel,
	useDataBoxMessageFormContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { useOutsideClick } from '@gov-nx/utils/web';
import { Button } from '../../Button/Button';
import { DataBoxRecipientDetailContent } from './DataBoxRecipientDetailContent';

export const DataBoxRecipientDetail = ({
	dataBox,
}: {
	dataBox: DatovkaApiType;
}) => {
	const { onRecipientRemove } = useDataBoxMessageFormContext();
	const [showDetail, setShowDetail] = useState(false);
	const ref = useOutsideClick<HTMLDivElement>(() => setShowDetail(false));
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	return (
		<GovFormControl className="ds-new-message">
			<label className={'gov-form-label__label'}>
				{tn('nova-zprava.komu')}:
				<span className={'gov-color--error-500'}>&nbsp;*</span>
			</label>
			<GovDropdown open={showDetail}>
				<GovTag
					variant="primary"
					size="xs"
					inverse
					onGov-click={() => setShowDetail(true)}>
					{dataBoxLabel(dataBox, true)}
					<Button
						variant="primary"
						type="solid"
						wcagLabel={tn('nova-zprava.zavrit-vse')}
						size="xs"
						onClick={onRecipientRemove}>
						<GovIcon
							slot="right-icon"
							name="x-lg"
						/>
					</Button>
				</GovTag>
				<div
					slot={'list'}
					ref={ref}
					className="pt-2">
					<DataBoxRecipientDetailContent dataBox={dataBox} />
				</div>
			</GovDropdown>
		</GovFormControl>
	);
};
