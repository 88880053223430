import { GovChip } from '@gov-design-system-ce/react';
import { useCustomElementTriggerAlias, useUrl } from '@gov-nx/web';
import React from 'react';

export interface BadgeNavigationItemProps {
	name: string;
	link?: string;
	isDisabled?: boolean;
}

export interface BadgeNavigationProps {
	items: BadgeNavigationItemProps[];
}

export const BadgeNavigation = ({ items }: BadgeNavigationProps) => {
	return (
		<nav>
			<ul
				className={`gov-list--plain pt-2 pb-4 flex gap-4 overflow-x-auto whitespace-nowrap [ xl:overflow-x-visible ]`}>
				{items
					.filter((item) => !item.isDisabled)
					.map((item, i) => {
						return (
							<li key={item.name}>
								<BadgeNavigationItem {...item} />
							</li>
						);
					})}
			</ul>
		</nav>
	);
};

export const BadgeNavigationItem = ({
	link,
	name,
}: BadgeNavigationItemProps) => {
	const { ref } = useCustomElementTriggerAlias<HTMLGovChipElement>();
	const { pathName } = useUrl();
	return (
		<GovChip
			ref={ref}
			variant={'primary'}
			inverse={pathName !== link}
			href={link}>
			{name}
		</GovChip>
	);
};
