import { useCallback } from 'react';
import {
	fetchUserSettingsQuery,
	isUnauthorizedResponse,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { PoIdentityType, useIdentity } from '@gov-nx/auth/common';
import { LocalizationLanguage, useAppContext } from '@gov-nx/core/app';
import { Nullable } from '@gov-nx/core/types';
import { useUserStore } from '@gov-nx/store/portal-obcana';
import { getFavoritesFromDTO } from '@gov-nx/utils/common';
import { useUserLanguageLoader } from './userLanguageLoader';

const queryKey = ['user-settings-app-portal-obcana'];

interface UserSettingsLoader {
	persistLanguage: (language: LocalizationLanguage) => Promise<void>;
	initUserSettings: () => Promise<void>;
}

export const useUserSettingsLoader = (): UserSettingsLoader => {
	const { setAppError } = useAppContext();
	const { setUserSettingFavorites, setUserSettingLanguage } = useUserStore();
	const poIdentityContext = useIdentity() as Nullable<PoIdentityType>;
	const { updateLanguage } = useUserLanguageLoader();

	const persistLanguage = async (language: LocalizationLanguage) => {
		setUserSettingLanguage(language);

		if (poIdentityContext?.isAuthenticated) {
			updateLanguage(language);
		}
	};

	const initQuery = usePoQuery({
		queryFn: fetchUserSettingsQuery,
		onSuccess: (data) => {
			if (!data?.seznam) {
				setAppError(new Error(`Invalid user settings`));
				return;
			}

			const favorites = data.seznam
				? getFavoritesFromDTO(data.seznam)
				: undefined;
			if (favorites) {
				setUserSettingFavorites(favorites.value);
			}
		},
		onError: (e) => {
			if (isUnauthorizedResponse(e) === false) {
				setAppError(e as Error);
			}
		},
		queryKey,
		enabled: false,
		retry: 1,
	});

	const fetchUserSettings = useCallback(async (): Promise<void> => {
		await initQuery.refetch();
	}, [initQuery]);

	return { initUserSettings: fetchUserSettings, persistLanguage };
};
