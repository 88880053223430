import { GovBackdrop, GovIcon } from '@gov-design-system-ce/react';
import { routeResolver } from '@gov-nx/web';
import cx from 'classnames';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslationWithNamespace } from '@gov-nx/core/service';
import {
	DataBoxFolderType,
	useDataBoxesContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { Button } from '@gov-nx/ui/web';
import { useTitle } from '@gov-nx/utils/web';
import { DataBoxAddCreditModal } from './DataBoxAddCreditModal';
import { DataBoxStorageUsage } from './DataBoxStorageUsage';
import { DataBoxesList } from './DataBoxesList';
import { DataBoxesNavigation } from './DataBoxesNavigation';

export const DataBoxesPage = () => {
	const { getLocalizeCurried } = useTranslationWithNamespace();
	const tn = getLocalizeCurried(PageCode['datove-schranky']);

	useTitle(tn('datove-schranky'));
	const { pathname } = useLocation();
	const {
		dataBoxId,
		folderType,
		dataBoxes,
		isDataBoxesLoaded,
		mobileMenuDataBox,
		setMobileMenuDataBox,
		disableNewMessageButton,
		lastOpened,
		isDataBoxConnected,
	} = useDataBoxesContext();

	if (!isDataBoxesLoaded) return null;

	if (dataBoxes.length === 0) {
		return (
			<Navigate
				to={routeResolver(PageCode['dashboard'])}
				replace={true}
			/>
		);
	}

	if (pathname.match(/^\/datove-schranky\/?$/) && dataBoxes.length > 0) {
		const firstConnectedDataBox = dataBoxes.find(
			(dataBox) => dataBox.odpojeno === false
		);
		if (lastOpened && isDataBoxConnected(lastOpened)) {
			return (
				<Navigate
					to={routeResolver(PageCode['datove-schranky-seznam'], {
						dataBoxId: lastOpened,
						folderType: DataBoxFolderType.Received,
					})}
					replace={true}
				/>
			);
		}

		if (firstConnectedDataBox) {
			return (
				<Navigate
					to={routeResolver(PageCode['datove-schranky-seznam'], {
						dataBoxId: firstConnectedDataBox.datovaSchrankaId,
						folderType: DataBoxFolderType.Received,
					})}
					replace={true}
				/>
			);
		}
		return (
			<Navigate
				to={routeResolver(PageCode['datove-schranky-seznam'], {
					dataBoxId: dataBoxes[0].datovaSchrankaId,
					folderType: DataBoxFolderType.Archived,
				})}
				replace={true}
			/>
		);
	}

	return (
		<>
			{mobileMenuDataBox && (
				<GovBackdrop onGov-click={() => setMobileMenuDataBox(null)} />
			)}

			<aside
				className={cx(
					'absolute top-[61px] bottom-0 left-0 px-3 pt-3 pb-6 w-[72px] flex-col justify-between bg-secondary-300 overflow-x-visible',
					'[ xl:flex xl:relative xl:top-0 xl:grow-0 xl:shrink-0 xl:w-[228px] xl:px-0 xl:py-4 ]',
					mobileMenuDataBox ? 'flex z-[301]' : 'hidden'
				)}>
				<div
					className={
						'flex flex-col items-center overflow-hidden [ xl:pb-8 xl:items-start ]'
					}>
					<Button
						href={routeResolver(PageCode['datove-schranky-nova'], {
							dataBoxId,
							folderType,
						})}
						variant={'primary'}
						type={'solid'}
						className={'hidden [ xl:block ]'}
						disabled={disableNewMessageButton}
						wcagLabel={tn('zpravy.wcag.nova-zprava')}>
						<GovIcon
							slot={'left-icon'}
							name={'plus-circle'}
						/>
						{tn('zpravy.nova-zprava')}
					</Button>

					<div className={'flex-1 overflow-y-auto [ xl:mt-4 ]'}>
						<DataBoxStorageUsage />

						<DataBoxesList list={dataBoxes} />
					</div>
				</div>

				<DataBoxesNavigation />

				<DataBoxAddCreditModal />
			</aside>
		</>
	);
};
