import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Tree } from '@gov-nx/ui/types';
import { useTree } from '@gov-nx/utils/common';

interface FormCheckboxesHookProps {
	tree: Tree;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formMethods: UseFormReturn<any>;
}

export type TreeFormCheckboxes = ReturnType<typeof useTreeFormCheckboxes>;

export const useTreeFormCheckboxes = ({
	formMethods,
	tree,
}: FormCheckboxesHookProps) => {
	const operations = useTree(tree);

	const isAnyFieldChecked = (): boolean => {
		return !!getAllFields().find((name) => {
			return formMethods.watch(name) === true;
		});
	};

	const sumChildren = (children: { name: string }[]): number => {
		return children.reduce((all, current) => {
			return formMethods.watch(current.name) === true ? all + 1 : all;
		}, 0);
	};
	const getTree = (): Tree => {
		return {
			...tree,
			children: tree.children.map((field) => {
				return {
					...field,
					label: `${field.label} (${sumChildren(field.children)}/${
						field.children.length
					})`,
				};
			}),
		};
	};

	const getChildren = (name: string): string[] => {
		return (
			tree.children
				.find((field) => field.name === name)
				?.children.map((c) => c.name) ?? []
		);
	};

	const getAllFields = (): (Tree['name'] &
		Tree['children'][number]['name'] &
		Tree['children'][number]['children'][number]['name'])[] => {
		return [
			tree.name,
			...tree.children.reduce(
				(all, current) => [
					...all,
					current.name,
					...current.children.map((c) => c.name),
				],
				[] as string[]
			),
		];
	};

	const getGroupFields = (): Tree['children'][number]['name'][] => {
		return tree.children.reduce(
			(all, current) => [...all, current.name],
			[] as string[]
		);
	};

	const change =
		(names: string[]) =>
		(isChecked: boolean): void => {
			names.forEach((name) => {
				formMethods.setValue(name, isChecked);
			});
		};

	const isRootField = (name: string) => name === tree.name;

	const isGroupField = (
		name: string
	): name is Tree['children'][number]['name'] =>
		!!tree.children.find((c) => c.name === name);

	const changeChildren = (name: string) => change(getChildren(name));
	const changeRootField = change(getGroupFields());

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const realField = (name: string, value: any) => {
		if (name.indexOf('.') > -1) {
			const [parent, fieldName] = name.split('.');
			return {
				isChecked: !!value[parent][fieldName],
			};
		}
		return {
			isChecked: !!value[name],
		};
	};

	useEffect(() => {
		const subscription = formMethods.watch((value, { name }) => {
			if (name) {
				const { isChecked } = realField(name, value);
				if (isRootField(name)) {
					changeRootField(isChecked);
					if (isChecked) {
						operations.openNode(name);
					}
				}

				if (isGroupField(name)) {
					changeChildren(name)(isChecked);
					if (isChecked) {
						operations.openNode(name);
					}
				}
			}
		});

		return () => subscription.unsubscribe();
	}, [formMethods.watch, formMethods]);

	return {
		operations,
		getTree,
		reset: operations.reset,
		isAnyFieldChecked,
		isRootField,
		isGroupField,
	};
};
