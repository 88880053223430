import { FileUploadItem, isFileUploadItemNative } from '@gov-nx/core/hooks';

// TODO: react-native has it is own implementation of FormData, maybe investigate more
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fileToFormData = (file: FileUploadItem): File | any => {
	if (isFileUploadItemNative(file)) {
		// react-native has its own FormData implementation
		return {
			uri: file.uri,
			type: file.type,
			name: file.name,
		};
	}
	return file.file;
};
