import { useEffect, useRef } from 'react';

export const useOutsideClick = <T extends HTMLElement>(
	callback: () => void
) => {
	const ref = useRef<T | null>(null);

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as T)) {
				callback();
			}
		};
		document.addEventListener('click', handleClick, true);

		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	}, [ref]);

	return ref;
};
