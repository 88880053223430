import { GovIcon } from '@gov-design-system-ce/react';
import { Trans, useTranslation } from 'react-i18next';
import {
	DataBoxListStatesType,
	useDataBoxActionInfoContext,
} from '@gov-nx/module/data-box';
import { PageCode } from '@gov-nx/module/page';
import { ListStateList } from './ListStateList';

export const DataBoxActionInfoPage = () => {
	const { listState } = useDataBoxActionInfoContext();
	const { t } = useTranslation([PageCode['datove-schranky']]);

	switch (listState) {
		case DataBoxListStatesType.List:
			return <ListStateList />;

		case DataBoxListStatesType.Search:
			return (
				<div className={'px-6 py-4 h-full'}>
					<div className={'flex h-full justify-center items-center'}>
						<div className={'w-[400px] flex flex-col gap-6'}>
							<img
								src="\assets\images\search.svg"
								width="102"
								height="64"
								alt=""
							/>
							<h4 className={'text-l font-bold'}>
								{t('info-stranka.vyhledavani.vyhledavani')}
							</h4>
							<div className={'flex flex-col gap-2'}>
								<p className={'flex gap-2 items-center font-bold'}>
									<GovIcon
										name="lightbulb-fill"
										className={'inline-flex grow-0 shrink-0 w-4'}
									/>
									{t('info-stranka.vyhledavani.podle')}
								</p>
								<ul className={'ml-6 text-secondary-700'}>
									<li className="before:!bg-secondary-700">
										{t('info-stranka.vyhledavani.predmet')}
									</li>
									<li className="before:!bg-secondary-700">
										{t('info-stranka.vyhledavani.odesilatel')}
									</li>
									<li className="before:!bg-secondary-700">
										{t('info-stranka.vyhledavani.schranka')}
									</li>
									<li className="before:!bg-secondary-700">
										{t('info-stranka.vyhledavani.zprava')}
									</li>
								</ul>
								<p>
									<Trans
										t={t}
										i18nKey="info-stranka.vyhledavani-slozka"
										components={{ strong: <strong /> }}
									/>
								</p>
							</div>
						</div>
					</div>
				</div>
			);

		case DataBoxListStatesType.MassArchive:
		case DataBoxListStatesType.SearchMassArchive:
			return (
				<div className={'px-6 py-4 h-full'}>
					<div
						className={
							'flex flex-col gap-6 h-full justify-center items-center text-center'
						}>
						<img
							src="\assets\images\archive-copy.svg"
							width="102"
							height="64"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>
								{t('info-stranka.kopirovani-do-archivu')}
							</h4>
							<p className={'text-secondary-700'}>
								{t('info-stranka.najdete-datovka')}
							</p>
						</div>
					</div>
				</div>
			);

		case DataBoxListStatesType.MassRemove:
		case DataBoxListStatesType.SearchMassRemove:
			return (
				<div className={'px-6 py-4 h-full'}>
					<div
						className={
							'flex flex-col gap-6 h-full justify-center items-center text-center'
						}>
						<img
							src="\assets\images\bin.svg"
							width="102"
							height="64"
							alt=""
						/>
						<div className={'flex flex-col gap-2'}>
							<h4 className={'text-l font-bold'}>
								{t('info-stranka.vybrane-smazani')}
							</h4>
							<p className={'text-secondary-700'}>
								{t('info-stranka.automaticke-odstraneni')}
							</p>
						</div>
					</div>
				</div>
			);

		case DataBoxListStatesType.MassPermanentRemove:
		case DataBoxListStatesType.SearchMassPermanentRemove:
			return (
				<div className={'px-6 py-4 h-full'}>
					<div
						className={
							'flex flex-col gap-6 h-full justify-center items-center text-center'
						}>
						<img
							src="\assets\images\bin-empty.svg"
							width="102"
							height="64"
							alt=""
						/>
						<h4 className={'text-l font-bold'}>
							{t('info-stranka.kos-odstranit')}
						</h4>
					</div>
				</div>
			);

		case DataBoxListStatesType.MassRestore:
		case DataBoxListStatesType.SearchMassRestore:
			return (
				<div className={'px-6 py-4 h-full'}>
					<div
						className={
							'flex flex-col gap-6 h-full justify-center items-center text-center'
						}>
						<img
							src="\assets\images\bin-archive.svg"
							width="102"
							height="64"
							alt=""
						/>
						<h4 className={'text-l font-bold'}>
							{t('info-stranka.kos-obnovit')}
						</h4>
					</div>
				</div>
			);
		default:
			return null;
	}
};
