import { GovDropdown } from '@gov-design-system-ce/react';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { Button, DataBoxRecipientDetailContent } from '@gov-nx/ui/web';
import { useBoolean } from '@gov-nx/utils/common';
import { useOutsideClick } from '@gov-nx/utils/web';

interface DataBoxMessageDataBoxDetailProps {
	dataBox: DatovkaApiType;
	label: string;
	position?: 'left' | 'right';
}

export const DataBoxMessageDataBoxDetail = ({
	dataBox,
	label,
	position,
}: DataBoxMessageDataBoxDetailProps) => {
	const showDetail = useBoolean(false);
	const ref = useOutsideClick<HTMLDivElement>(() => showDetail.setFalse);

	return (
		<GovDropdown
			open={showDetail.value}
			position={position}
			className={'ds-bussines-card'}>
			<Button
				variant="primary"
				size="l"
				type="link"
				onClick={() => showDetail.setTrue}
				wcagLabel={label}>
				<span className="font-normal">{label}</span>
			</Button>
			<div
				slot={'list'}
				ref={ref}
				className="pt-2">
				<DataBoxRecipientDetailContent dataBox={dataBox} />
			</div>
		</GovDropdown>
	);
};
