import { GovModal } from '@gov-design-system-ce/react';
import { OsobyCertifikatHistorieDto } from '@gov-nx/api/portal-obcana';
import { useLocale } from '@gov-nx/core/service';
import { getEntries, Optional } from '@gov-nx/core/types';
import { PageCode } from '@gov-nx/module/page';
import { Button, DataTable } from '@gov-nx/ui/web';
import {
	getFormattedPublisher,
	toDateReadable,
	toDateTimeReadable,
	useBoolean,
} from '@gov-nx/utils/common';

const Table = ({ rows }: { rows: Record<string, Optional<string>> }) => {
	return (
		<DataTable>
			<tbody>
				{getEntries(rows).map(([key, value]) => (
					<tr key={key}>
						<td>{key}</td>
						<td>
							<strong>{value ?? '-'}</strong>
						</td>
					</tr>
				))}
			</tbody>
		</DataTable>
	);
};

interface Props {
	history: OsobyCertifikatHistorieDto[];
}

export const HistoryModal = ({ history }: Props) => {
	const code = PageCode['profil-certifikaty'];
	const { t } = useLocale(code);
	const isOpen = useBoolean(false);

	return (
		<>
			<GovModal
				label={t('certifikaty.historie-certifikatu.modal-titulek')}
				open={isOpen.isTrue}
				onGov-close={isOpen.setFalse}>
				{history.map((item, index) => {
					return (
						<div key={index}>
							{item.datumZmeny ? (
								<div>{toDateTimeReadable(item.datumZmeny)}</div>
							) : null}
							<Table
								rows={{
									[t('certifikaty.historie-certifikatu.vydavatel')]:
										item.vydavatel ? getFormattedPublisher(item.vydavatel) : '',
									[t('certifikaty.historie-certifikatu.seriove-cislo')]:
										item.serioveCislo,
									[t('certifikaty.historie-certifikatu.platnost-do')]:
										item.platnostDo
											? toDateReadable(item.platnostDo)
											: undefined,
									[t('certifikaty.historie-certifikatu.typ-zmeny')]:
										item.operace
											? t(
													`certifikaty.historie-certifikatu.operace.${item.operace}`
											  )
											: '-',
								}}
							/>
						</div>
					);
				})}
			</GovModal>
			<Button
				onClick={isOpen.setTrue}
				nativeType="submit"
				variant="primary"
				disabled={history.length === 0}
				size="l"
				type="outlined"
				wcagLabel={t('akce.zobraz-historii')}>
				{t('akce.zobraz-historii')}
			</Button>
		</>
	);
};
