import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { FormDefinition } from '@gov-nx/core/service';
import { hasOwnProperty } from '@gov-nx/core/types';

type WizardStep<T extends object> = Record<string, never> | FormDefinition<T>;

const isFormStep = <T extends object>(
	step: WizardStep<T>
): step is FormDefinition<T> => hasOwnProperty(step, 'formMethods');

interface StepsCommonProps {
	isSubmitted: boolean;
	isExpanded: boolean;
	isFocused: boolean;
	isCollapsible: boolean;
}

export interface WizardStandardStep extends StepsCommonProps {
	type: 'standard';
}

export interface WizardFormStep<T extends object> extends StepsCommonProps {
	type: 'form';
	formDefinition: FormDefinition<T>;
	onSubmit: (
		onValid?: (canContinue: boolean) => void
	) => (event: BaseSyntheticEvent) => void;
	onSubmitAsync: () => (
		event: BaseSyntheticEvent
	) => Promise<{ canContinue: boolean }>;
}

export type FormWizardHook<T extends object> = {
	formData: T;
	openedSteps: number[];
	openStep: (index: number) => void;
	closeStep: (index: number) => void;
	openNextStep: (index: number) => void;
	resetForms: () => void;
	step: (index: number) => WizardStandardStep | WizardFormStep<T>;
	submit: (onSubmitted?: () => void) => void;
};

interface WizardProps<T> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	steps: WizardStep<any>[];
	initialIndexOpen?: number[];
	onSubmit: (values: T, onSubmitted?: () => void) => Promise<void>;
	onStepSubmitted?: (index: number) => Promise<{ canContinue: boolean }>;
	canInitialize?: boolean;
}

export const useWizard = <T extends object>({
	steps,
	onSubmit,
	onStepSubmitted,
	initialIndexOpen,
	canInitialize = true,
}: WizardProps<T>): FormWizardHook<T> => {
	const [submittedSteps, setSubmittedSteps] = useState<number[]>([]);
	const [openedSteps, setOpenedSteps] = useState<number[]>([]);
	const [formData, setFormData] = useState<T>({} as T);
	const [focusedStep, setFocusedStep] = useState<number | undefined>();

	const defaultFromShapes = () =>
		steps.reduce((all, step) => {
			return isFormStep(step)
				? { ...all, ...step.formSchema.getDefaultFromShape() }
				: all;
		}, {} as T);

	useEffect(() => {
		if (canInitialize) {
			setFormData(defaultFromShapes());
			setOpenedSteps(initialIndexOpen ?? [0]);
		}
	}, [canInitialize]);

	return {
		formData,
		openStep: (index: number) => {
			setOpenedSteps((old) => [...old, index]);
			setFocusedStep(index);
		},
		closeStep: (index: number) =>
			setOpenedSteps((old) => old.filter((f) => f !== index)),
		openNextStep: (index: number) => {
			setOpenedSteps([index]);
			setFocusedStep(index);
		},
		submit: (onSubmitted) => onSubmit(formData, onSubmitted),
		resetForms: () => {
			steps.filter(isFormStep).forEach((step) => {
				step.formReset();
			});
			setOpenedSteps([0]);
			setFocusedStep(0);

			setSubmittedSteps([]);
			setFormData(defaultFromShapes());
		},
		openedSteps,
		step: (index) => {
			const step = steps[index];

			const isExpanded = openedSteps.includes(index);
			const isSubmitted = submittedSteps.includes(index);
			const isFocused = focusedStep === index;
			const isCollapsible =
				(index < Math.max(...openedSteps) && !isExpanded) ||
				(isSubmitted && !isExpanded);

			const common: StepsCommonProps = {
				isExpanded,
				isSubmitted,
				isFocused,
				isCollapsible,
			};
			if (isFormStep(step)) {
				const handleSubmit = (
					event: BaseSyntheticEvent,
					onValid?: (canContinue: boolean) => void,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onInValid?: (errors: any) => void
				) =>
					step.formMethods.handleSubmit(async () => {
						const merged = { ...formData, ...step.formMethods.getValues() };

						setFormData(merged);
						setSubmittedSteps((old) => [...old, index]);

						if (index < steps.length - 1) {
							if (onStepSubmitted) {
								const { canContinue } = await onStepSubmitted(index);
								if (!canContinue) {
									onValid && onValid(false);
									return;
								}
							}
							setOpenedSteps([index + 1]);
							setFocusedStep(index + 1);
							onValid && onValid(true);
						} else {
							await onSubmit(merged);
						}
					}, onInValid)(event);

				return {
					...common,
					type: 'form',
					formDefinition: step,
					onSubmit: (onValid) => async (event) => {
						await handleSubmit(event, onValid);
					},
					onSubmitAsync: () => (event) =>
						new Promise((resolve, reject) => {
							return handleSubmit(
								event,
								(canContinue) => resolve({ canContinue }),
								reject
							);
						}),
				};
			}

			return {
				...common,
				type: 'standard',
			};
		},
	};
};
