import {
	compare,
	getProperty,
	isNumber,
	isString,
	map,
	pipe,
} from '@gov-nx/core/types';
import {
	DataDetail,
	DataResponse,
	INode,
	RealEstateLV,
	RealEstateLVRaw,
} from './context.types';
import {
	reduceAddSpread,
	reduceAdd,
	reduceAddSpreadCurried,
	reduceAddCurried,
} from './helpers';

const xVal = <T extends { xVal: string }>(
	node: INode<T>
): string | undefined => {
	if (!node) {
		return undefined;
	}
	if (Array.isArray(node)) {
		return xVal(node[0]);
	}
	if (isNumber(node)) {
		return `${node}`;
	}
	if (isString(node)) {
		return node;
	}

	return getProperty(node, 'xVal');
};

const prop = (property: string) => (object: INode<unknown>) => {
	if (!object) return [];

	if (isString(object)) return [];
	if (isNumber(object)) return [];

	if (Array.isArray(object)) {
		return object.reduce((all, current) => {
			const value = getProperty(current, property);
			if (value) {
				if (Array.isArray(value)) {
					return [...all, ...value];
				}

				return [...all, value];
			}
			return all;
		}, []);
	}

	const value = getProperty(object, property as keyof typeof object);
	if (value) {
		if (Array.isArray(value)) {
			return value;
		}
		return [value];
	}
	return [];
};

interface IResult {
	obce: Array<{
		nazev?: string;
		katastry: Array<{
			nazev?: string;
			telesa: Array<{
				lv?: string;
				telId?: string;
				podil?: { citatel?: string; jmenovatel?: string };
			}>;
		}>;
	}>;
}

export const formatItemData = (response: DataResponse): RealEstateLVRaw[] => {
	const subjects = pipe(
		response.Data?.[0]?.r,
		prop('kNOdpoved'),
		prop('kontextData'),
		prop('generujSestavuResponse'),
		prop('reportList'),
		prop('report'),
		prop('souborSestavy'),
		prop('evidencePravProOsobu'),
		prop('uplnaShoda'),
		prop('vlastnictvi'),
		prop('opravSubjekt')
	);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const result: IResult[] = map((subject) => ({
		obce: pipe(
			subject,
			prop('pravVztahy'),
			prop('typrav'),
			prop('okresy'),
			prop('okres'),
			prop('obce'),
			prop('obec'),
			map((obec) => {
				const katastry = pipe(
					obec,
					prop('katastry'),
					reduceAddSpreadCurried((katastry) => {
						return pipe(
							katastry,
							prop('katastr'),
							reduceAddCurried((katastr) => {
								const katastrNazev = pipe(
									katastr,
									prop('katuze'),
									prop('nazev'),
									xVal
								);

								const telesa = pipe(
									katastr,
									prop('telesa'),
									prop('teleso'),
									map((teleso) => {
										return {
											lv: pipe(teleso, prop('lv'), xVal),
											telId: pipe(teleso, prop('telId'), xVal),
											podil: pipe(teleso, prop('podil'), (share) => {
												const podil = share.reduce(
													// eslint-disable-next-line @typescript-eslint/ban-ts-comment
													// @ts-ignore
													(all, current) =>
														current ? { ...all, ...current } : all,
													null
												);

												if (!podil) return;
												return {
													citatel: pipe(podil, prop('citatel'), xVal),
													jmenovatel: pipe(podil, prop('jmenovatel'), xVal),
												};
											}),
										};
									})
								);

								return { nazev: katastrNazev, telesa };
							})
						);
					})
				);

				const obecNazev = pipe(obec, prop('obec'), prop('nazev'), xVal);
				return { nazev: obecNazev, katastry };
			})
		),
	}))(subjects);

	const final: RealEstateLVRaw[] = reduceAddSpread((subject) => {
		return reduceAddSpread((obec) => {
			return reduceAddSpread((katastr) => {
				return reduceAdd((teleso) => {
					return {
						katastr: katastr.nazev,
						obec: obec.nazev,
						lv: teleso.lv,
						telId: teleso.telId,
						podil: teleso.podil,
					};
				}, katastr.telesa);
			}, obec.katastry);
		}, subject.obce);
	}, result);

	return final.sort(compare(['obec', 'katastr', 'lv']));
};

export const getDetailsFormatted = (
	lvRaw: RealEstateLVRaw,
	detail: DataDetail
): RealEstateLV => {
	return { ...lvRaw, link: detail.Data[0]?.po?.nemovitost?.url?.xVal };
};
