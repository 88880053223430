import { GovIcon, GovTile, GovTiles } from '@gov-design-system-ce/react';
import { useCustomElementTriggerAlias } from '@gov-nx/web';
import React from 'react';
import { parseIcon } from '@gov-nx/component/common';
import { TileLinkItemProps, TileLinkListProps } from '@gov-nx/ui/types';

export const TileLinkList = ({ items }: TileLinkListProps) => {
	return (
		<GovTiles
			columns={3}
			noBorder>
			{items.map((item, i) => (
				<TileLinkListItem
					key={i}
					{...item}
				/>
			))}
		</GovTiles>
	);
};

export const TileLinkListItem = ({ label, link, icon }: TileLinkItemProps) => {
	const { ref } = useCustomElementTriggerAlias<HTMLGovTileElement>();
	const parsedIcon = icon ? parseIcon(icon) : null;
	return (
		<GovTile
			ref={ref}
			href={link || undefined}>
			<h3 slot={'title'}>{label}</h3>
			{parsedIcon ? (
				<GovIcon
					name={parsedIcon.name}
					type={parsedIcon.type}
					slot="icon"></GovIcon>
			) : null}
		</GovTile>
	);
};
