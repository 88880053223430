export const getFormattedPublisher = (publisher: string): string => {
	if (/^[\w\s]+=.*$/i.test(publisher)) {
		const parts = publisher.split(',');
		let name = '';
		const dcParts: string[] = [];
		let countryPart = '';

		for (const part of parts) {
			const keyValue = part.split('=');
			if (keyValue.length === 2) {
				const key = keyValue[0].trim();
				const value = keyValue[1].trim();
				if (key.toUpperCase() === 'CN') {
					name = value;
				} else if (key.toUpperCase() === 'C') {
					countryPart = value;
				} else if (key.toUpperCase().startsWith('DC')) {
					dcParts.push(value);
				}
			}
		}

		let dcPartStr = '';
		if (dcParts.length > 0) {
			dcPartStr = ` ${dcParts.join(', ')}`;
		}

		return `${name}, ${dcPartStr}${countryPart ? ` ${countryPart}` : ''}`;
	} else {
		return publisher;
	}
};
