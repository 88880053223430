import { isNumber, Nullable, Optional } from '@gov-nx/core/types';

export const replaceVariablesInUrl = (
	inputString: string,
	valueObject: Nullable<Record<string, Optional<string | number>>>
): string => {
	const queryParams: string[] = [];

	if (typeof valueObject === 'object') {
		for (const key in valueObject) {
			if (Object.prototype.hasOwnProperty.call(valueObject, key)) {
				const value = valueObject[key];
				const colonKey = `:${key}`;

				if (inputString.includes(colonKey) && value) {
					inputString = inputString.replace(
						colonKey,
						isNumber(value) ? value.toString() : value
					);
				} else {
					queryParams.push(`${key}=${value}`);
				}
			}
		}
	}

	if (queryParams.length > 0) {
		const queryString = queryParams.join('&');
		inputString += inputString.includes('?')
			? `&${queryString}`
			: `?${queryString}`;
	}

	return inputString;
};
