import { create } from 'zustand';
import { DatovkaApiType } from '@gov-nx/api/portal-obcana';
import { is } from '@gov-nx/core/types';
import { DataBoxState } from './store.types';

const initialState = {
	isLoaded: false,
	list: [],
	credit: {},
	lastOpenedDatabox: undefined,
	isStorageAccordionOpen: true,
};

export const useDataBoxStore = create<DataBoxState>()((set, get) => ({
	...initialState,

	setDataBoxList: (list: DatovkaApiType[]) => set({ list, isLoaded: true }),
	dataBoxReset: () => set({ ...initialState }),
	removeDataBox: (databoxId: string) =>
		set((state: DataBoxState) => ({
			list: state.list.filter((item) => item.datovaSchrankaId !== databoxId),
		})),
	setLastOpenedDatabox: (databoxId: string) =>
		set({ lastOpenedDatabox: databoxId }),
	setIsStorageAccordionOpen: (isStorageAccordionOpen: boolean) =>
		set({ isStorageAccordionOpen }),

	getConnectedDataBoxesList: () =>
		get().list.filter((dataBox) => dataBox.odpojeno === false),
	getConnectedDataBoxesIds: () =>
		get()
			.list.filter((dataBox) => dataBox.odpojeno === false)
			.map((dataBox) => dataBox.datovaSchrankaId)
			.filter(is),
	getDisconnectedDataBoxes: () =>
		get().list.filter(
			(dataBox) => dataBox.odpojeno === true || dataBox.odpojeno === undefined
		),
	getConnectedAndDisconnectedDataBoxesList: () => [
		...get().list.filter((dataBox) => dataBox.odpojeno === false),
		...get().list.filter((dataBox) => dataBox.odpojeno === true),
	],
	getDataBoxesToConnect: () =>
		get()
			.list.filter((dataBox) =>
				dataBox?.stav ? ['EXISTUJE', 'ZALOZENA'].includes(dataBox.stav) : false
			)
			.filter((dataBox) => dataBox.odpojeno !== false),
	getDataBoxesToCreate: () =>
		get().list.filter((dataBox) => dataBox?.stav === 'LZE_ZALOZIT'),
	getDataBoxById: (id: DatovkaApiType['datovaSchrankaId']) =>
		get().list.find((box) => box.datovaSchrankaId === id),
	getDataBoxByType: (type: DatovkaApiType['typSchranky']) =>
		get().list.find((box) => box.typSchranky === type),
	getConnectedDataboxesListByTypes: (types: DatovkaApiType['typSchranky'][]) =>
		get()
			.getConnectedDataBoxesList()
			.filter((databox) => types.includes(databox.typSchranky)),
}));
