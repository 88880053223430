import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovButton } from '@gov-design-system-ce/react';
import { useCustomElementTriggerAlias } from '@gov-nx/web';
import { ReactNode } from 'react';

interface FormButtonProps
	extends Omit<JSX.GovButton, 'onGov-click' | 'loading'> {
	children?: ReactNode;
	onClick?: () => void;
	loading?: boolean;
	className?: string;
	slot?: string;
	enforceLink?: boolean;
	title?: string;
}

export const Button = (props: FormButtonProps) => {
	const { ref } = useCustomElementTriggerAlias<HTMLGovButtonElement>({
		enforceLink: props?.enforceLink,
	});
	const { onClick, loading, children, ...rest } = props;

	return (
		<GovButton
			{...rest}
			loading={String(loading)}
			ref={ref}
			onGov-click={() => {
				onClick && onClick();
			}}>
			<>{children}</>
		</GovButton>
	);
};
