import React, { createContext, useContext, useEffect, useState } from 'react';
import { Nullable, first } from '@gov-nx/core/types';
import { useMyFilesStorage } from '@gov-nx/module/page';
import { useDataBoxStore } from '@gov-nx/store/portal-obcana';
import { useDataBoxCreditLoader } from '../hooks/useDataBoxCredit';
import { useDataBoxSettings } from '../hooks/useDataBoxSettings';
import { useDataBoxUnreadMessages } from '../hooks/useDataBoxUnreadMessagesCounts';
import { DataBoxFolderType } from './DataBoxes.types';
import { DataBoxesContextType } from './DataBoxesContext.types';

export const DataBoxesContext =
	createContext<Nullable<DataBoxesContextType>>(null);

interface DataBoxesProviderProps {
	dataBoxId: string;
	folderType: DataBoxFolderType;
	children: React.ReactNode;
}

export function DataBoxesProvider({
	dataBoxId: initedDataBoxId,
	folderType: initedFolderType,
	children,
}: DataBoxesProviderProps) {
	const [dataBoxId, setDataBoxId] =
		useState<DataBoxesContextType['dataBoxId']>(initedDataBoxId);
	const [folderType, setFolderType] =
		useState<DataBoxesContextType['folderType']>(initedFolderType);

	const { getConnectedAndDisconnectedDataBoxesList } = useDataBoxStore();
	const dataBoxes = getConnectedAndDisconnectedDataBoxesList();
	const {
		isLoaded: isDataBoxesLoaded,
		getConnectedDataBoxesList,
		getConnectedDataBoxesIds,
		lastOpenedDatabox: lastOpened,
	} = useDataBoxStore();
	const connectedDataBoxes = getConnectedDataBoxesList();
	const connectedDataboxesIds = getConnectedDataBoxesIds();
	const { isLoaded: isCreditsLoaded } = useDataBoxCreditLoader();
	const firstDataBox = first(dataBoxes);

	const storage = useMyFilesStorage();

	const isDataBoxConnected = (dataBoxId: string) =>
		connectedDataboxesIds.includes(dataBoxId);

	const [creditModalDataBoxId, setCreditModalDataBoxId] =
		useState<Nullable<string>>(null);

	const closeCreditModal = () => {
		setCreditModalDataBoxId(null);
	};

	const handleCreditModalOpen = (dataBoxId: string) => {
		setCreditModalDataBoxId(dataBoxId);
	};

	const [mobileMenuDataBox, setMobileMenuDataBox] =
		useState<Nullable<string>>(null);

	const disableNewMessageButton = !connectedDataBoxes.length;

	useEffect(() => {
		setDataBoxId(initedDataBoxId);
	}, [initedDataBoxId]);

	useEffect(() => {
		setFolderType(initedFolderType);
	}, [initedFolderType]);

	const { automaticArchiveEnabled, notificationsEnabled } = useDataBoxSettings({
		dataBoxId: initedDataBoxId,
	});

	const { getUnreadMessagesCounts } = useDataBoxUnreadMessages();

	return (
		<DataBoxesContext.Provider
			value={{
				setDataBoxId,
				setFolderType,
				dataBoxes,
				firstDataBox,
				isDataBoxesLoaded: isCreditsLoaded && isDataBoxesLoaded,
				dataBoxId,
				folderType,
				storage,
				creditModalDataBoxId,
				closeCreditModal,
				handleCreditModalOpen,
				mobileMenuDataBox,
				setMobileMenuDataBox,
				disableNewMessageButton,
				automaticArchiveEnabled,
				notificationsEnabled,
				lastOpened,
				isDataBoxConnected,
				getUnreadMessagesCounts,
			}}>
			{children}
		</DataBoxesContext.Provider>
	);
}

export const useDataBoxesContext = (): DataBoxesContextType =>
	useContext(DataBoxesContext) as DataBoxesContextType;
