export const reduceAddSpread = <I, O>(fn: (t: I) => O[], array: I[]) => {
	return array.reduce((all, current) => {
		return [...all, ...fn(current)];
	}, [] as O[]);
};

export const reduceAddSpreadCurried =
	(fn: Parameters<typeof reduceAddSpread>[0]) =>
	(array: Parameters<typeof reduceAddSpread>[1]) =>
		reduceAddSpread(fn, array);

export const reduceAdd = <I, O>(fn: (t: I) => O, array: I[]) => {
	return array.reduce((all, current) => {
		return [...all, fn(current)];
	}, [] as O[]);
};
export const reduceAddCurried =
	(fn: Parameters<typeof reduceAdd>[0]) =>
	(array: Parameters<typeof reduceAdd>[1]) =>
		reduceAdd(fn, array);
