import {
	isFavoritesDTO,
	NastaveniApiActualDto,
	UserSettingFavorites,
} from '@gov-nx/api/portal-obcana';
import { Optional } from '@gov-nx/core/types';

export const getFavoritesFromDTO = (
	setting: NastaveniApiActualDto[]
): Optional<UserSettingFavorites> => {
	const favorites = setting.find(isFavoritesDTO);
	if (favorites) {
		return { key: favorites.klic, value: favorites.hodnota };
	}
};
