import { useEffect, useRef, useState } from 'react';
import { useConfiguration } from '@gov-nx/core/service';
import { Optional } from '@gov-nx/core/types';

interface useCaptchaProps {
	when?: boolean;
}

interface WindowWithCaptcha extends Window {
	grecaptcha: {
		ready: (callback: () => void) => void;
		execute: (key: string, options: { action: string }) => Promise<string>;
	};
	captchaScriptLoaded?: () => void;
}

declare const window: WindowWithCaptcha;

export const useCaptcha = (props?: useCaptchaProps) => {
	const { captchaKey } = useConfiguration();
	const [captchaToken, setCaptchaToken] = useState<Optional<string>>(undefined);
	const captchaRan = useRef(false);

	const loadCaptcha = () => {
		if (!captchaKey) {
			return;
		}
		const captchaScript = document.createElement('script');
		captchaScript.type = 'text/javascript';
		captchaScript.src =
			'https://www.google.com/recaptcha/api.js?onload=captchaScriptLoaded&render=' +
			captchaKey;
		document.head.appendChild(captchaScript);
		const captchaStyle = document.createElement('style');
		captchaStyle.innerHTML = '.grecaptcha-badge { visibility: hidden; }';
		document.head.appendChild(captchaStyle);

		window.captchaScriptLoaded = () => {
			const grecaptcha = window.grecaptcha;

			grecaptcha?.ready(() => {
				grecaptcha
					.execute(captchaKey, { action: 'technickapodpora' })
					.then((token) => {
						setCaptchaToken(token);
					});
				delete window.captchaScriptLoaded;
			});
		};
	};

	const initCaptcha = () => {
		if (props?.when || !props) {
			loadCaptcha();
		}
	};

	useEffect(() => {
		!captchaRan.current && initCaptcha();
		captchaRan.current = true;
	}, []);

	return { captchaToken };
};
